<template>
	<div>
		<!--Main Content section START-->
		<section class="mainsc_content legal-main-section">
			<div class="container">
				<div class="legal-content">
					<h1>{{ $t("privacyPage.pageTitle") }}</h1>
					<div v-html="$t('privacyPage.content')"></div>
				</div>
			</div>
		</section>
		<!--END main section-->
	</div>
</template>

<script>
	import MainPage from "@/layouts/Main_Layout.vue";
	export default {
		//this for layout call
		created() {
			this.$parent.$emit("update:layout", MainPage);
		},
		//this for layout render
		render() {
			return this.$slots.default[0];
		},
		//Meta info title + description + others meta
		metaInfo() {
			return {
				title: this.$t("metaInfo.privacyTitle"),
				htmlAttrs: {
					lang: this.$i18n.locale,
				},
				meta: [
					{ name: "description", content: this.$t("metaInfo.privacyDesc") },
				],
			};
		},
		//end meta info
	};
</script>

<style lang="scss" scoped></style>
